'use client';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';

const faqs = [
  {
    id: 1,
    question: 'Does Penno support multiple languages?',
    answer:
      'Yes, we offer multi-lingual support, allowing you to write and edit content in various languages.',
  },
  {
    id: 2,
    question: "How does Penno's AI-powered writing assistance work & learn?",
    answer:
      'Our AI technology analyzes your writing and provides suggestions to improve clarity, grammar, and style, helping you refine your content. It also will learn your writing style, so as you use the product more, it will learn how to generate texts according to your writing tone & style.',
  },
  {
    id: 3,
    question: 'Can I collaborate with others in real-time using the Penno?',
    answer:
      'Yes, our editor allows you to invite others to edit your document simultaneously, making collaboration seamless.',
  },
  {
    id: 4,
    question: 'How does import, export and integration works?',
    answer: ' Penno supports multiple file format for export and import including various formats, including .docx, .pdf, .html, .tex and more. Also Penno provides integration based on your tier. We have capability to integrate to other platforms like confluence and also import any public webpage.',
  },
  {
    id: 5,
    question: "How does Penno's Autocomplete feature work?",
    answer:
      "Penno analyzes your writing and suggests relevant word completions, phrases, or even sentence structures to help you overcome writer's block and keep your writing flowing.",
  },
  {
    id: 6,
    question: "Can I turn off Penno's Autocomplete suggestions?",
    answer: "Yes, you should be able to enable or disable the Autocomplete feature within Penno's settings.",
  },
];

export default function Faq() {
  return (
    <div className="mx-auto max-w-4xl px-6 lg:px-8 flex flex-col items-center">
      <div className="bg-white rounded-[20px] px-3 py-1 ring-2 ring-gray-500/5 w-fit mx-auto mb-4">FAQs</div>
      <h2 className="text-2xl sm:text-4xl font-bold">Frequently asked questions</h2>
      <p className="mt-6 max-w-2xl text-base leading-7 text-gray-600 text-center">
        <span>
          {' '}
          Have a different question and can’t find the answer you’re looking for? Reach out to our support team by{' '}
        </span>
        <a href="mailto:team@penno.io" className="font-semibold text-primary hover:text-primary/90">
          sending us an email
        </a>
        <span> and we’ll get back to you as soon as we can. </span>
      </p>

      <Accordion type="single" collapsible className="w-full pt-14">
        {faqs.map(faq => (
          <AccordionItem value={faq.id.toString()} key={faq.id}>
            <AccordionTrigger className="sm:text-xl py-6 text-start">{faq.question}</AccordionTrigger>
            <AccordionContent>{faq.answer}</AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>

      <div className="mt-20">
        <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10"></dl>
      </div>
    </div>
  );
}
