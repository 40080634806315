'use client';
import { useRouter } from 'next/navigation';
import posthog from 'posthog-js';
import { useState, useRef } from 'react';
import { Pause, Play } from 'lucide-react';

export default function Hero() {
  const router = useRouter();

  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null); // UseRef with HTMLVideoElement type

  const togglePlay = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  return (
    <div className="relative isolate overflow-hidden">
      <div className="mx-auto max-w-6xl px-6 pb-24 pt-10 sm:pb-32 lg:px-8 lg:py-10 text-center">
        <div className="max-w-2xl lg:max-w-2xl lg:flex-shrink-0 pt-8 lg:pt-12 flex flex-col items-start text-start">
          <div>
            <a href="/templates" className="inline-flex space-x-6">
              <span className="rounded-full bg-primary/10 px-3 py-1 text-xs font-semibold leading-6 text-primary ring-1 ring-inset ring-primary/10">
                What's new &bull; Introducting templates
              </span>
            </a>
          </div>
          <h1 className="mt-4 text-3xl font-semibold tracking-tighter text-gray-900 sm:text-7xl">
            Write and research <span className="text-primary">10X</span> faster!
          </h1>
          <p className="mt-4 text-lg leading-6 text-pretty font-medium tracking-tighter text-gray-800 max-w-xl">
            Our AI-powered platform lets you research from various sources, including your own documents, and write
            seamlessly in one place.
          </p>
          <div className="flex items-center gap-8">
            <div
              onClick={() => {
                posthog.capture('sign_up_cta');
                router.push('/sign-up');
              }}
              className="cursor-pointer mt-4 flex items-center gap-2 rounded-2xl bg-primary px-4 py-2.5 text-lg font-medium text-white shadow-sm hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white transition-all duration-300"
            >
              Join now – it's free!
            </div>
          </div>
        </div>
        <div className="mx-auto py-16">
          <div className="max-w-3xl flex-none sm:max-w-6xl lg:max-w-none">
            <div className="relative rounded-xl bg-gray-900/5 p-2 lg:rounded-2xl lg:p-4">
              <video
                ref={videoRef}
                poster="/screenshot.png"
                width="2432"
                height="1442"
                preload="none"
                className="w-[80rem] rounded-md ring-1 ring-gray-900/10"
                autoPlay={isPlaying}
                loop
                muted
                playsInline
                onClick={togglePlay}
              >
                <source src="/desktop.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              <button
                onClick={e => {
                  e.preventDefault();
                  togglePlay();
                }}
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full focus:outline-none p-3"
                style={{
                  display: isPlaying ? 'none' : 'block',
                  zIndex: 10,
                }}
              >
                <Play />
              </button>
              <button
                onClick={e => {
                  e.preventDefault();
                  togglePlay();
                }}
                className="absolute right-10 bottom-8 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full focus:outline-none p-3"
                style={{
                  display: isPlaying ? 'block' : 'none',
                  zIndex: 10,
                }}
              >
                <Pause />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
